import * as React from "react";
import { Layout } from "../../components/layout";

const IndexPage = () => {
  return (
    <>
      <Layout title="Welpenkauf">
        <main className="container">
          <h1>Überlegungen vor dem Kauf eines Golden Retriever Welpen:</h1>

          <h3 className="pt-3">Was ist das für ein Hund?</h3>
          <p>
            Reichen meine allgemeinen Kenntnisse aus, weiß ich über die
            Bedürfnisse eines Golden Retriever Bescheid?
          </p>
          <p>
            Bin ich bereit, mich mit der retrievergerechten Ausbildung zu
            befassen und die Art der Erziehung zu erlernen?
          </p>

          <h3 className="pt-3">Zeitfaktor:</h3>
          <p>
            Bin ich bereit, während den nächsten 10-15 Jahren tagtäglich, immer
            - bei jedem Wetter - viel persönliche Freiheit für meinen Golden zu
            opfern? Bin ich gerne in der Natur? Freue ich mich auf die
            Ausbildung und das Zusammenleben mit meinem Hund? Erlauben mir meine
            Hobbys, auch am Wochenende meinem Golden genügend Zeit zu widmen?
          </p>
          <p>
            Wie gestalte ich die Zukunft meiner Ferien? Nicht alle Strände sind
            während der Hauptsaison für Hunde offen - Skipisten sind tabu. Bin
            ich grundsätzlich bereit, andere Ferien zu planen, wo der Hund
            integriert ist, oder habe ich eine Bezugsperson, die ihn während
            meiner Ferien betreut?
          </p>

          <h3 className="pt-3">Wohnsituation:</h3>
          <p>
            Ist meine Wohnsituation überhaupt für Hundehaltung geeignet? Ist ein
            Versäuberungsplatz in vernünftiger Distanz erreichbar? Retriever
            sind begnadete Gärtner. Habe ich einen Garten zum spielen und toben
            mit meinem Hund oder einen englischen Rasen mit Teich? Als Mieter
            muss schriftlich abgeklärt sein, ob Haustiere gestattet sind.
          </p>

          <h3 className="pt-3">Mutter und Hausfrau:</h3>
          <p>
            Bin ich als Mutter eines noch nicht schulpflichtigen Kindes
            überhaupt in der Lage, einem Hund noch die nötige Aufmerksamkeit,
            Zeit und Liebe entgegenzubringen? Finde ich die nötige Unterstützung
            in der Familie und im Umfeld?
          </p>
          <p>
            Ein Hund eignet sich nicht als Spielzeug für Kinder - das bekommt
            man bei Toy’s are us.
          </p>

          <h3 className="pt-3">Beruf und Hund:</h3>
          <p>
            Als halbtags berufstätige Person muss ich wissen, dass ein
            tagtäglich stundenlang allein gelassener Hund jämmerlich verkümmern
            wird. Der Golden ist ein sensibler und sehr menschenbezogener Hund.
            Er liebt und braucht seine Menschen, Körperkontakt, Streicheln und
            Zuwendung. Als erwachsener Hund sollte er regelmäßig nie länger als
            4 Stunden alleine sein. An voll berufstätige Personen geben wir
            keine Welpen ab.
          </p>

          <h3 className="pt-3">Der finanzielle Aufwand:</h3>
          <p>
            Die Kostenfrage ist ein nicht unwesentlicher Faktor. Für Futter,
            Hundesteuer, Versicherung, Tierarzt, Ausbildung ist pro Jahr mit
            etwa 500-600 Euro zu rechnen. Die Anschaffungskosten für die
            Erstausstattung ( Leine, Körbchen, usw.) sowie eine Autobox kommen
            im ersten Jahr noch dazu. Für meine Welpenfamilien gibt es die
            Möglichkeit, gebrauchte Kennel (für´s Auto) von meinen “ älteren “
            Welpenfamilien abzukaufen. Vorausgesetzt natürlich, es sind gerade
            welche abgegeben worden.
          </p>

          <p className="pt-5">
            Wenn Sie alles mit ja beantworten können, steht einem glücklichen
            Zusammenleben mit einem Golden nichts mehr im Wege.
          </p>
          <p>Quellenauszug: FC Retriever v. Rosemarie Wild.</p>
        </main>
      </Layout>
    </>
  );
};

export default IndexPage;
